<template>
  <div :class="classes" @click="$emit('click')">
    <div :class="contentClasses">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseCard",
  props: {
    clickable: {
      type: Boolean,
      required: false,
      default: () => {
        return false;
      },
    },
    noPadding: {
      type: Boolean,
      required: false,
      default: () => {
        return false;
      },
    },
  },
  computed: {
    classes() {
      return {
        card: true,
        clickable: this.clickable,
      };
    },
    contentClasses() {
      return {
        "card-content": true,
        "no-padding": this.noPadding,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  display: block;
  background-color: $white;
  border: 1px solid $default-border;
  border-radius: 12px;
  box-shadow: $shadow-default;
  overflow: hidden;
  transition: all 0.2s ease;
}
.card-content {
  padding: 10px;
}
.no-padding {
  padding: 0;
}
.clickable {
  &:hover {
    cursor: pointer;
    box-shadow: 0px 0px 10px 0px rgba(160, 160, 170, 0.5);
  }
}
</style>
