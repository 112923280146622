<template>
  <div class="application-main">
    <NewMainHeader background="rgb(55, 68, 88)" />
    <MainSidebar />
    <div class="not-found-body">
      <div class="not-found-container">
        <p class="not-found-404">404</p>
        <p class="not-found-text">
          {{ $t("notFound.message") }}
        </p>
      </div>
    </div>
    <MainFooter />
  </div>
</template>

<script>
import VueI18n from "@/plugins/i18n";
import NewMainHeader from "@/components/shared-components/master-layout/components/new-main-header/NewMainHeader.vue";
import MainSidebar from "@/components/common/MainSidebar.vue";
import MainFooter from "@/components/common/MainFooter.vue";

export default {
  name: "PageNotFound",
  components: {
    NewMainHeader,
    MainSidebar,
    MainFooter,
  },
};
</script>

<style lang="scss" scoped>
.application-main {
  background-color: #e9e9e9;
  display: flex;
  flex-direction: column;
  height: inherit;
  overflow: auto;

  > *:not(footer):not(.header-container):not(aside) {
    box-sizing: border-box;
    max-width: 1920px;
    width: 100%;
    margin: 0 auto;
  }

  footer {
    margin-top: auto;
  }
}

.not-found-container {
  > * {
    margin: 0;
  }
}

.not-found-body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.not-found-404 {
  font-size: 100px;
}
</style>
