<template>
  <nav class="nav-cart">
    <a-dropdown :trigger="['click']">
      <a-badge :show-zero="false" :count="uniqueItems" type="primary" class="cart-badge">
        <font-awesome-icon icon="shopping-cart" size="lg" />
      </a-badge>
    </a-dropdown>
  </nav>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { isEmpty } from "lodash";

export default {
  name: "NavCart",
  components: {},
  data() {
    return {
      qtyUpdating: false,
    };
  },
  computed: {
    ...mapGetters(["getCartItems", "getCurrentSelectedLocation"]),
    uniqueItems() {
      let items = 0;
      this.getCartItems.forEach((item) => {
        items += item.qty;
      });
      return items;
    },
    totalItems() {
      let items = 0;
      this.getCartItems.forEach((item) => {
        items += item.qty;
      });
      return items;
    },
  },
  created() {
    if (isEmpty(this.getCartItems)) {
      this.fetchActiveCart(this.getCurrentSelectedLocation);
    }
  },
  methods: {
    ...mapActions(["fetchgetCartItems", "updateItemQty", "removeItemFromCart", "fetchActiveCart", "emptyActiveCart"]),
    handleQtyChange(productId, newQty) {
      this.qtyUpdating = true;
      const payload = {
        locationId: this.getCurrentSelectedLocation,
        productId,
        qty: newQty,
      };
      this.updateItemQty(payload).then(() => {
        this.qtyUpdating = false;
      });
    },
    handleProductRemove(productId) {
      this.qtyUpdating = true;
      const payload = {
        locationId: this.getCurrentSelectedLocation,
        productId,
      };
      this.removeItemFromCart(payload).then(() => {
        this.qtyUpdating = false;
      });
    },
    handleClickCheckout() {
      this.$router
        .push({ name: "NewCheckout", params: { locationId: this.getCurrentSelectedLocation } })
        .catch(() => {});
    },
    handleEmptyCart() {
      this.emptyActiveCart(this.getCurrentSelectedLocation);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_colors.scss";
.nav-cart {
  width: max-content;
  margin-left: auto;
  ul {
    margin: 0;
    padding-left: 0;

    li {
      color: $white;
      display: inline-flex;
      font-size: 10px;
      font-weight: 200;
      text-transform: uppercase;

      a {
        color: $white;
        text-decoration: none;

        &:hover,
        &:active {
          text-decoration: underline;
        }
      }

      &:not(:first-of-type) {
        margin-left: 20px;
      }

      .el-dropdown-link {
        font-size: 10px;
        font-weight: 200;
        text-transform: uppercase;
      }
    }
  }

  .ant-dropdown-trigger {
    color: $white;
    cursor: pointer;

    .fa-chevron-down,
    .fa-shopping-cart {
      margin-left: 5px;
    }
  }
}
</style>
