<template>
  <div>
    <div class="no-search-results-main">
      <h1 class="sorry-text">
        {{ $t('noSearchResults.header') }}
      </h1>
      <h1 id="h12">
        {{ $t('noSearchResults.subHeader') }}
      </h1>
      <ul>
        <p>{{ $t('noSearchResults.tipOne') }}</p>
        <p>{{ $t('noSearchResults.tipTwo') }}</p>
        <p>{{ $t('noSearchResults.tipThree') }}</p>
      </ul>
    </div>
  </div>
</template>

<script>
import VueI18n from '@/plugins/i18n';

export default {
  name: 'NoSearchResults',
};
</script>

<style lang="scss" scoped>

.no-search-results-main{
    padding-top: 5vh;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    text-align:center;
    background-color: #f5f7fa;
}
ul{
    margin-top: -1vh;
}
h1{
    font-weight:600;
    color: $orange;
    font-size: 35px;
    padding-left:9%;
    padding-right:9%;
    line-height: 1.5;
}

h2{
    margin-top: -1.5vh;
    font-weight: normal;
    font-size:30px;
    color: $dark-blue;
}

p{
    font-size:20px;
    color: $dark-blue;
    line-height:1vh;
    font-weight:500;
}

#h12{
    margin-top: -3vh;
}

</style>
